import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';

// const momPic = require("../assets/images/MOM.jpeg")

const styles = (theme) => ({
    wrapper: {
        minHeight: 'calc(100vh - 90px)'
    },
    body: {
        margin: '20px 0px'
    },
    textStyle: {
        color: theme.colorPalette.white
    }
})

class About extends Component {

  render() {
    var { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <PageTitle title="About Me"/>
        <div className={classes.body}>
              {/* <img src={momPic} alt="Mom Pic" width="600" height="800" /> */}
              <div className={classes.textStyle}>Write a little blurb about yourself</div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(About);