import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import NavBar from './components/NavBar';
import Theme from './utilities/Theme';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import FAQ from './pages/FAQ';
import CustomerFeedback from './pages/CustomerFeedback';
import { withStyles } from '@material-ui/core/styles';
import './App.css';
import backgroundImage from './assets/images/landscapeBackground.jpg';


const styles = (theme) => ({
  background: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    flex: 1,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  body: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
})


class App extends Component {
  render() {
    var { classes } = this.props;
    return (
      <div className={classes.background}>
        <div className={classes.body} >
          <ThemeProvider theme={Theme}>
            <Router>
              <NavBar />
              {/* <Footer /> */}
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/About/">
                  <About />
                </Route>
                <Route exact path="/Services/">
                  <Services />
                </Route>
                <Route exact path="/Portfolio/">
                  <Portfolio />
                </Route>
                <Route exact path="/FAQ/">
                  <FAQ />
                </Route>
                <Route exact path="/Feedback/">
                  <CustomerFeedback />
                </Route>
              </Switch>
            </Router>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
