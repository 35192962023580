import {createMuiTheme} from '@material-ui/core/styles';

const Theme = createMuiTheme({
    status: {
        danger: 'red',
    },
    colorPalette: {
        green: "#4c7228",
        darkBrown: "#302718",
        lightBrown: "#7e551b ",
        white: "#FFFFFF",
        tan: "#DED2A8",
        darkGrey: "#333333"
    }
});

export default Theme
