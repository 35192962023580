import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageTitle from '../components/PageTitle';


const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  }
})

class FAQ extends Component {
  render() {
    var { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <PageTitle title="FAQ"/>
      </div>
    )
  }
}

export default withStyles(styles)(FAQ);