import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';

const display1 = require("../assets/images/Display1.png");
const display2 = require("../assets/images/Display2.png");
const display3 = require("../assets/images/Display3.png");
const display4 = require("../assets/images/Display4.png");

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  body: {
  }
})

class Home extends Component {
  render() {
    var { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {/* <PageTitle title="Home"/> */}
        <div className={classes.body}>
          <Carousel>
              <img src={display1} alt="Display1" width="800" height="600" />
              <img src={display2} alt="Display2" width="800" height="600" />
              <img src={display3} alt="Display3" width="800" height="600" />
              <img src={display4} alt="Display4" width="800" height="600" />
          </Carousel>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Home);