import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  appBar: {
    padding: '28px 0px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    float:'center',
    margin: '0px 15px',
    color: theme.colorPalette.white,
    padding: '0 0 0 7%'
  },
  links: {
    textDecoration: "none",
    color: theme.colorPalette.white,
    margin: '0px 15px',
    float:'right',
  }
})

class NavBar extends Component {
  render() {
    var { classes } = this.props;

    return (
        <div className={classes.appBar} >
          <text className={classes.title}>My Backyard Plants</text>
          <Link to='/Feedback/' className={classes.links}>Customer Feedback</Link>
          <Link to='/FAQ/' className={classes.links}>FAQ</Link>
          <Link to='/About/' className={classes.links}>About</Link>
          <Link to='/Portfolio/' className={classes.links}>Portfolio</Link>
          <Link to='/Services/' className={classes.links}>Pricing & Services</Link>
          <Link to='/' className={classes.links}>Home</Link>
        </div>
    )
  }
}

export default withStyles(styles)(NavBar);