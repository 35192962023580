import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  titleWrapper: {
    padding: '40px 0px',
  },
  title: {
      fontWeight: 700,
      fontSize: '20px',
      color: theme.colorPalette.white
  }
})

class PageTitle extends Component {
  
  render() {
    var { classes, title } = this.props;
    return (
        <div className={classes.titleWrapper} >
            <div className={classes.title}>{title}</div>
        </div>
    )
  }
}

export default withStyles(styles)(PageTitle);